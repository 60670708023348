import {
  Box, Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBasicDataCommerce, getStates } from '../../api/services';
import i18n from '../../components/common/i18n';
import Loading from '../../components/common/Loading';
import CommerceBoxMainData from './commerceBoxMainData';
import CommerceBoxManagerData from './commerceBoxManagerData';
import CommerceBoxSignDisclaimer from './commerceBoxSignDisclaimer';
import CommerceBoxUploadDocuments from './commerceBoxUploadDocuments';

const commerceForm = () => {
  const { idCommerce } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const COMMERCE_DATA_BOX = 'commerce_data_box';
  const COMMERCE_MANAGER_BOX = 'commerce_manager_box';
  const DOC_UPLOAD_BOX = 'doc_upload_box';
  const DISCLAIMER_BOX = 'disclaimer_box';

  const [selectedBox, setSelectedBox] = useState(undefined);

  const selectBox = (box) => {
    setSelectedBox(selectedBox !== box ? box : undefined);
  };

  // main data
  const [mainDataSuccess, setMainDataSuccess] = useState(false);
  const [taxIdField, setTaxIdField] = useState('');
  const [companyNameField, setCompanyNameField] = useState('');
  const [fantasyNameField, setFantasyNameField] = useState('');
  const [states, setStates] = useState([]);
  const [provinceField, setProvinceField] = useState('');
  const [cityField, setCityField] = useState('');
  const [postalCodeField, setPostalCodeField] = useState('');
  const [addressNameField, setAddressNameField] = useState('');
  const [addressNumberField, setAddressNumberField] = useState('');
  const [floorField, setFloorField] = useState('');
  const [apartmentField, setApartmentField] = useState('');
  const [phoneField, setPhoneField] = useState('');
  const [emailField, setEmailField] = useState('');
  const [cbuField, setCbuField] = useState('');

  // manager data
  const [managerDataSuccess, setManagerDataSuccess] = useState(false);
  const [managerNameField, setManagerNameField] = useState('');
  const [managerLastNameField, setManagerLastNameField] = useState('');
  const [managerDocumentNumberField, setManagerDocumentNumberField] = useState('');
  const [managerPhoneField, setManagerPhoneField] = useState('');
  const [managerEmailField, setManagerEmailField] = useState('');

  // upload documents
  const [uploadDNIFrontSuccess, setUploadDNIFrontSuccess] = useState(false);
  const [uploadDNIBackSuccess, setUploadDNIBackSuccess] = useState(false);
  const [uploadDocumentCUITSuccess, setUploadDocumentCUITSuccess] = useState(false);
  const [uploadDocumentAdditionalSuccess, setUploadDocumentAdditionalSuccess] = useState(false);
  const [signDisclaimerSuccess, setSignDisclaimerSuccess] = useState(false);

  const validateDataBeforeSetManagerDataSuccess = (commerceData) => {
    if (
      commerceData.commerceManager_name && commerceData.commerceManager_name.length > 0
          && commerceData.commerceManager_lastName && commerceData.commerceManager_lastName.length > 0
          && commerceData.commerceManager_documentNumber && commerceData.commerceManager_documentNumber.length > 0
          && commerceData.commerceManager_phone && commerceData.commerceManager_phone.length > 0
          && commerceData.commerceManager_email && commerceData.commerceManager_email.length > 0) {
      return true;
    }
    return false;
  };

  const validateDataBeforeSetMainDataSuccess = (commerceData) => {
    if (
      commerceData && commerceData.phone && commerceData.phone.length > 0
          && commerceData.email && commerceData.email.length > 0
          && commerceData.cbu && commerceData.cbu.length > 0) {
      return true;
    }
    return false;
  };

  const openPendingForm = () => {
    if (!mainDataSuccess) {
      return selectBox(COMMERCE_DATA_BOX);
    }
    if (!managerDataSuccess) {
      return selectBox(COMMERCE_MANAGER_BOX);
    }
    if (!uploadDNIFrontSuccess
      || !uploadDNIBackSuccess
      || !uploadDocumentCUITSuccess) {
      return selectBox(DOC_UPLOAD_BOX);
    }
    if (!signDisclaimerSuccess) {
      return selectBox(DISCLAIMER_BOX);
    }
    return undefined;
  };

  const closeForm = () => {
    selectBox();
    openPendingForm();
  };

  useEffect(async () => {
    setIsLoading(true);
    try {
      const { data } = await getBasicDataCommerce(idCommerce);
      const { commerceData, documentList } = data;

      // main data
      setTaxIdField(data.taxId);
      setCompanyNameField(data.name);
      setFantasyNameField(data.fantasyName);
      if (commerceData) {
        setProvinceField(commerceData.province);
        setCityField(commerceData.city);
        setPostalCodeField(commerceData.postalCode);
        setAddressNameField(commerceData.addressName);
        setAddressNumberField(commerceData.addressNumber);
        setFloorField(commerceData.floor);
        setApartmentField(commerceData.apartment);
        setPhoneField(commerceData.phone);
        setEmailField(commerceData.email);
        setCbuField(commerceData.cbu);
        setMainDataSuccess(validateDataBeforeSetMainDataSuccess(commerceData));
      }
      // manager data
      if (commerceData) {
        setManagerNameField(commerceData.commerceManager_name);
        setManagerLastNameField(commerceData.commerceManager_lastName);
        setManagerDocumentNumberField(commerceData.commerceManager_documentNumber);
        setManagerPhoneField(commerceData.commerceManager_phone);
        setManagerEmailField(commerceData.commerceManager_email);
        setManagerDataSuccess(validateDataBeforeSetManagerDataSuccess(commerceData));
      }

      // documents uploaded
      if (documentList) {
        setUploadDNIFrontSuccess(documentList.some((d) => d.type === 'DNI_FRENTE'));
        setUploadDNIBackSuccess(documentList.some((d) => d.type === 'DNI_DORSO'));
        setUploadDocumentCUITSuccess(documentList.some((d) => d.type === 'CONSTANCIA_CUIT'));
        setUploadDocumentAdditionalSuccess(documentList.some((d) => d.type === 'DOCUMENTOS_ADICIONALES'));
        setSignDisclaimerSuccess(documentList.some((d) => d.type === 'CONTRATO'));
      }

      // get provinces
      const statesResponse = await getStates();
      const { data: statesData } = statesResponse;
      setStates(statesData);
    } catch (error) {
      console.error('There was an error!', error);
      if (error.response.status === 404) {
        setErrorMessage(i18n.welcomeCommerceError);
      }
    }
    setIsLoading(false);
  }, []);

  return (
    <>
      {
        errorMessage ? (
          <Box sx={{
            width: '250px', marginTop: '5%', marginLeft: { xs: '5%', sm: '30%', md: '40%' },
          }}
          >
            <div />
            <Stack sx={{ margin: '0!important', width: '100%' }}>
              <Alert severity="error">
                {errorMessage}
              </Alert>
            </Stack>
          </Box>
        ) : (
          isLoading === true ? (
            <Loading />
          ) : (
            isLoading === false && (
            <>
              <Grid container>
                <Grid item md={3} sm={1} />
                <Grid item md={6} sm={10}>
                  <Box mt={8}>
                    <Grid item xs={1} md={2} />
                    {/* commerce_data_box */}
                    <Accordion
                      expanded={selectedBox === COMMERCE_DATA_BOX}
                      onChange={() => selectBox(COMMERCE_DATA_BOX)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{i18n.formCommerceTitleCommerceData}</Typography>
                        { mainDataSuccess === true && <DoneIcon style={{ color: 'green' }} /> }
                      </AccordionSummary>
                      <AccordionDetails>
                        <CommerceBoxMainData
                          mainDataSuccess={mainDataSuccess}
                          setMainDataSuccess={setMainDataSuccess}
                          taxIdField={taxIdField}
                          companyNameField={companyNameField}
                          fantasyNameField={fantasyNameField}
                          states={states}
                          provinceField={provinceField}
                          cityField={cityField}
                          postalCodeField={postalCodeField}
                          addressNameField={addressNameField}
                          addressNumberField={addressNumberField}
                          floorField={floorField}
                          apartmentField={apartmentField}
                          phoneField={phoneField}
                          emailField={emailField}
                          cbuField={cbuField}
                          closeForm={closeForm}
                        />
                      </AccordionDetails>
                    </Accordion>
                    {/* commerce_manager_box */}
                    <Accordion
                      expanded={selectedBox === COMMERCE_MANAGER_BOX}
                      onChange={() => selectBox(COMMERCE_MANAGER_BOX)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>{i18n.formCommerceTitleCommerceManagerData}</Typography>
                        { managerDataSuccess === true && <DoneIcon style={{ color: 'green' }} /> }
                      </AccordionSummary>
                      <AccordionDetails>
                        <CommerceBoxManagerData
                          managerDataSuccess={managerDataSuccess}
                          setManagerDataSuccess={setManagerDataSuccess}
                          managerNameField={managerNameField}
                          managerLastNameField={managerLastNameField}
                          managerDocumentNumberField={managerDocumentNumberField}
                          managerPhoneField={managerPhoneField}
                          managerEmailField={managerEmailField}
                          closeForm={closeForm}
                        />
                      </AccordionDetails>
                    </Accordion>
                    {/* doc_upload_box */}
                    <Accordion
                      expanded={selectedBox === DOC_UPLOAD_BOX}
                      onChange={() => selectBox(DOC_UPLOAD_BOX)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                      >
                        <Typography>{i18n.formCommerceTitleDocumentUpload}</Typography>
                        { uploadDNIFrontSuccess === true
                        && uploadDNIBackSuccess === true
                        && uploadDocumentCUITSuccess === true
                        && <DoneIcon style={{ color: 'green' }} /> }
                      </AccordionSummary>
                      <AccordionDetails>
                        <CommerceBoxUploadDocuments
                          uploadDNIFrontSuccess={uploadDNIFrontSuccess}
                          uploadDNIBackSuccess={uploadDNIBackSuccess}
                          uploadDocumentCUITSuccess={uploadDocumentCUITSuccess}
                          uploadDocumentAdditionalSuccess={uploadDocumentAdditionalSuccess}
                          setUploadDNIFrontSuccess={setUploadDNIFrontSuccess}
                          setUploadDNIBackSuccess={setUploadDNIBackSuccess}
                          setUploadDocumentCUITSuccess={setUploadDocumentCUITSuccess}
                          setUploadDocumentAdditionalSuccess={setUploadDocumentAdditionalSuccess}
                          closeForm={closeForm}
                        />
                      </AccordionDetails>
                    </Accordion>
                    {/* disclaimer_box */}
                    <Accordion
                      expanded={selectedBox === DISCLAIMER_BOX}
                      onChange={() => selectBox(DISCLAIMER_BOX)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                      >
                        <Typography>{i18n.formCommerceTitleSignAndFinish}</Typography>
                        { signDisclaimerSuccess === true && <DoneIcon style={{ color: 'green' }} /> }
                      </AccordionSummary>
                      <AccordionDetails>
                        <CommerceBoxSignDisclaimer
                          mainDataSuccess={mainDataSuccess}
                          managerDataSuccess={managerDataSuccess}
                          uploadDNIFrontSuccess={uploadDNIFrontSuccess}
                          uploadDNIBackSuccess={uploadDNIBackSuccess}
                          uploadDocumentCUITSuccess={uploadDocumentCUITSuccess}
                          setSignDisclaimerSuccess={setSignDisclaimerSuccess}
                          closeForm={closeForm}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  { signDisclaimerSuccess === true && (
                  <Alert severity="success">
                    {i18n.formCommerce_signIsSucess}
                  </Alert>
                  )}
                </Grid>
                <Grid item lg={4} md={3} sm={1} />
              </Grid>
            </>
            )
          )
        )
      }
    </>
  );
};

export default commerceForm;
