import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import Alert from '@mui/material/Alert';
import Loading from '../components/common/Loading';
import i18n from '../components/common/i18n';
import { createUserInSapp, getUserById } from '../api/services';
import { useUserData } from '../contexts/userDataContext';
import { fillMessageWith, translateErrorOrDefault } from '../utils/functionsUtil';

const sellerDetails = () => {
  const location = useLocation();
  const id = location.pathname.split('/').pop();
  const { userData } = useUserData();
  const [isLoading, setIsLoading] = useState(false);
  const [sellerData, setSellerData] = useState(undefined);
  const [showButtonSaveInSapp, setShowButtonSaveInSapp] = useState(false);
  const [showSuccessSaveInSapp, setShowSuccessSaveInSapp] = useState(false);
  const [detailsSaveInSappError, setDetailsSaveInSappError] = useState(undefined);

  const getSeller = async () => {
    setDetailsSaveInSappError(false);
    const { data } = await getUserById(id);
    setShowButtonSaveInSapp(data.status !== i18n.userFinalized);
    setSellerData(data);
    setShowSuccessSaveInSapp(false);
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      getSeller();
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  }, []);

  const onClickSaveInSapp = async () => {
    setIsLoading(true);
    try {
      setShowButtonSaveInSapp(false);
      const response = await createUserInSapp(id, userData.sub);
      if (response.status === 200) {
        setShowSuccessSaveInSapp(true);
      }
    } catch (error) {
      console.error('Hubo un error!', error);
      setDetailsSaveInSappError(translateErrorOrDefault(error.response.data?.code,
        fillMessageWith(i18n.COMMERCE_AFFILIATION_USER_REQUEST_ERROR_SAVING_IN_SAPP, error.response.data?.message)));
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && sellerData && (
        <Box mt={8}>
          <Grid container>
            <Grid item xs={1} md={2} />
            <Grid item xs={10} md={8}>
              <Box pb={5} style={{ border: '1px solid #b1a7a6' }}>
                <Box mt={5}>
                  <Typography variant="h3">{i18n.detailsSellerTitleLabel}</Typography>
                </Box>
                <Grid container>
                  <Grid item xs={12} md={12} className="textLeft">
                    <Box ml={4}>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsSellerBranchLabel}
                          <strong>
                            {`${sellerData.branch} ( ID: ${sellerData.branchId})`}
                          </strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.filterSellerFirstNameDetailLabel}
                          <strong>{sellerData.firstname}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.filterSellerLastNameDetailLabel}
                          <strong>{sellerData.lastname}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.filterSellerIdentificationNumberDetailLabel}
                          <strong>{sellerData.identificationNumber}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.filterSellerEmailDetailLabel}
                          <strong>{sellerData.email}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsSellerPhoneLabel}
                          <strong>{sellerData.phone}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsSellerCreatedDateLabel}
                          <strong>{sellerData.createdDate}</strong>
                        </Typography>
                      </Box>
                      <Box mt={5}>
                        <Typography variant="h5">
                          {i18n.detailsSellerCreatedByLabel}
                          <strong>{sellerData.createdBy}</strong>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={1} md={2} />
          </Grid>
          <Box />
          {detailsSaveInSappError ? (
            <Box mb={5}>
              <Grid container>
                <Grid item xs={1} md={2} />
                <Grid item xs={10} md={8}>
                  <Alert severity="error">
                    {detailsSaveInSappError}
                  </Alert>
                </Grid>
                <Grid item xs={1} md={2} />
              </Grid>
            </Box>
          ) : showButtonSaveInSapp ? (
            <Box mb={2}>
              <Grid container alignItems="center" justifyContent="center" style={{ paddingTop: 20 }} maxWidth>
                <Grid item xs={10} md={8}>
                  <Button variant="contained" onClick={onClickSaveInSapp}>{i18n.detailsSaveInSapp}</Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            showSuccessSaveInSapp && (
              <Box mt={5} mb={5}>
                <Grid container>
                  <Grid item xs={1} md={2} />
                  <Grid item xs={10} md={8}>
                    <Alert severity="success">
                      {i18n.detailsSaveInSappSuccessSeller}
                    </Alert>
                  </Grid>
                  <Grid item xs={1} md={2} />
                </Grid>
              </Box>
            )
          )}
          {(!showButtonSaveInSapp && (
            <Box mb={4} />
          ))}
        </Box>
      )}
    </>
  );
};

export default sellerDetails;
